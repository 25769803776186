import React, { createContext, useContext, useState } from 'react';
import api from '../auth';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('is-user-loged-id') ? true : false);

    const login = (username, password) => {
        return api
            .post('/api/login', {
                email: username,
                password: password
            })
            .then((res) => {
                localStorage.setItem('is-user-loged-id', true);

                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user', JSON.stringify(res.data.user));
                setIsLoggedIn(true);
            })
            .catch((error) => {
                // return reject
                return Promise.reject(error);
            });
    };

    // register
    const singin = (username, email, password, passwordConfirmation) => {
        return api
            .post('/api/register', {
                name: username,
                email: email,
                password: password,
                password_confirmation: passwordConfirmation
            })
            .then((res) => {
                localStorage.setItem('is-user-loged-id', true);
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user', JSON.stringify(res.data.user));

                setIsLoggedIn(true);
            });
    };

    const isLoggedin = () => {
        return isLoggedIn;
    };

    const logout = () => {
        return api.post('/api/logout').then((res) => {
            localStorage.removeItem('is-user-loged-id');
            //localStorage.removeItem('token');
            localStorage.removeItem('user');
            setIsLoggedIn(false);
        });
    };

    const removeUserRequest = (id) => {
        localStorage.removeItem('is-user-loged-id');
        //localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsLoggedIn(false);
        return api.delete(id);
    };

    const getCurrentUser = () => {
        // return api.get('user');
        // get user from local storage
        return JSON.parse(localStorage.getItem('user'));
    };

    const getCSRFToken = () => {
        return api.get('sanctum/csrf-cookie');
    };

    const googleCallback = (code) => {
        return api
            .get(`/api/auth/callback${code}`, {
                code: code
            })
            .then((res) => {
                localStorage.setItem('is-user-loged-id', true);
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user', JSON.stringify(res.data.user));
                setIsLoggedIn(true);
            });
    };

    const getAuthUser = () => {
        return api.get('/api/auth').then((res) => {
            return res.data;
        });
    };

    return <AuthContext.Provider value={{ removeUserRequest, login, googleCallback, singin, isLoggedin, logout, getCurrentUser, getAuthUser, getCSRFToken }}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};

export { AuthProvider, useAuth };
