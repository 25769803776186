import api from '../auth';
const slug = 'api/locations';

const LocationsService = () => {
    const getLocationss = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getLocationssLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getLocations = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const deleteLocations = (id) => {
        return api
            .delete(slug + `/location/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createLocations = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updateLocations = (id, data) => {
        return api.put(slug + `/location/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyLocations = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    const getLocationsByCode = (code) => {
        return api.get(slug + `/code/${code}`).then((res) => res.data);
    };

    return {
        getLocationss,
        getLocationssLazy,
        getLocations,
        deleteLocations,
        createLocations,
        updateLocations,
        getOptions,
        copyLocations,
        getLocationsByCode
    };
};

export default LocationsService;
