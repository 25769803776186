// Import UserService for CRUD operations
// Import all related models

import React from 'react';
import { useAuth } from '../hooks/auth-provider';
import { Button } from 'primereact/button';

export const Profile = () => {
    const { getCurrentUser, removeUserRequest } = useAuth();

    const { id, name, email } = getCurrentUser();

    return (
        <div className="card">
            <h1>Moj profil</h1>
            <div className="text-xl mt-2">Korisnički ID : #{id}</div>
            <div className="text-xl mt-2">Ime: {name}</div>
            <div className="text-xl mt-2 font-bold">Email: {email}</div>

            <div className="mt-4">
                <Button
                    label="Izbriši moje podatke"
                    onClick={() => {
                        removeUserRequest(id);
                    }}
                ></Button>
            </div>
        </div>
    );
};
