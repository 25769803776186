import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import QuestionnairesService from '../../service/QuestionnairesService';

import moment from 'moment';

const Questionnaires = () => {
    const [models, setModels] = useState();
    const [loading, setLoading] = useState(true);

    // init state for lazy loading
    // from local storage or default
    const [lazyState, setlazyState] = useState(JSON.parse(localStorage.getItem('dt-state-questionnaires-local')) || { first: 0, rows: 10, sortField: 'id', sortOrder: -1, filters: {} });

    const { getQuestionnairessLazy } = QuestionnairesService();

    useEffect(() => {
        loadLazyData();
    }, [lazyState]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        // convert filters to params
        let filters = {};
        for (const key in lazyState.filters) {
            if (lazyState.filters[key].value) {
                filters[key + '[value]'] = lazyState.filters[key].value;
                filters[key + '[matchMode]'] = lazyState.filters[key].matchMode;
            }
        }

        // convert layiState to params
        const params = {
            page: lazyState.first / lazyState.rows + 1,
            perPage: lazyState.rows,
            sortBy: lazyState.sortField,
            sortOrder: lazyState.sortOrder > 0 ? 'asc' : 'desc',
            ...filters
        };

        getQuestionnairessLazy(params).then((res) => {
            setModels(res.data);
            setLoading(false);
            // // console.log(res.meta.total);
            // setTotalRecords(res.meta.total);
        });
    };

    // calculate number of days from parama active_to
    const calculateDays = (active_from, active_to) => {
        console.log('active_from', active_from);
        console.log('active_to', active_to);

        const zavrsava = moment(active_to).diff(moment(), 'hours');
        const pocinje = moment(active_from).diff(moment(), 'hours');
        console.log('pocinje', pocinje);

        if (zavrsava < 0) {
            return 'zavrseno';
        }

        if (pocinje > 0) {
            return 'ceka';
        }

        return 'aktivno';
    };

    const renderQuestionnaire = (models) => {
        return (
            <div className="col-12" key={models.id}>
                <div className="p-6   shadow-2 flex flex-column md:flex-row align-items-center justify-content-between" style={{ borderRadius: '1rem', background: '#006E3D' }}>
                    <div className="pr-6 ">
                        <div className="text-white font-medium text-3xl">{models.title} </div>
                    </div>
                    {calculateDays(models.active_from, models.active_to) === 'aktivno' && <div className="pt-4 text-white  ">Glasanje se završava {moment(models.active_to).format('DD.MM.YYYY')} !</div>}
                    {calculateDays(models.active_from, models.active_to) === 'zavrseno' && <div className="pt-4 text-orange-500  ">Glasanje je završeno!</div>}
                    {calculateDays(models.active_from, models.active_to) === 'ceka' && <div className="pt-4 text-orange-500  ">Glasanje pozinje {moment(models.active_from).format('DD.MM.YYYY')}.</div>}
                    <div className="mt-4 mr-auto md:mt-0 md:mr-0">
                        {calculateDays(models.active_from, models.active_to) === 'aktivno' && (
                            <Link to={`/questionnaires/${models.id}/edit`}>
                                <Button label="Glasaj" className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="card">
            <h1>Anketa</h1>
            {/* map to questionnaires */}
            {loading && (
                <div className="flex align-items-center justify-content-center p-4 ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                        </span>
                    </div>
                </div>
            )}
            {models &&
                models.map((model) => {
                    return renderQuestionnaire(model);
                })}
            {/* if not models found */}
        </div>
    );
};

export default Questionnaires;
