// About us page  (src/pages/About.js)
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import PagesService from '../service/PagesService';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const Pages = () => {
    const [pages, setPages] = useState([]);

    const { getPagess } = PagesService();

    useEffect(() => {
        getPagess().then((res) => {
            setPages(res.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            {pages.map((page) => (
                <div className="col-12 md:col-6 xl:col-4 p-3" key={page.id}>
                    <div className="surface-card shadow-2 border-round border-round-2xl p-4">
                        <div className="flex ">
                            <div className="flex flex-column align-items-start">
                                <span className="text-2xl text-green-500 text-900 font-medium mb-1">{page.title}</span>
                            </div>
                        </div>
                        <div className="flex justify-content-between pt-4">
                            <Link to={`/post/${page.slug}`} className="">
                                <Button label="Pročitaj više" icon="pi pi-eye" className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap" />
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
            {/* <pre>{DOMPurify.sanitize(JSON.stringify(pages, null, 2))}</pre> */}
        </div>
    );
};

export default Pages;
