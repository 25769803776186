import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useNavigate } from 'react-router-dom';
import CitiesService from '../../service/CitiesService';
import LocationsService from '../../service/LocationsService';

import { Dropdown } from 'primereact/dropdown';

const Locations = () => {
    const [models, setModels] = useState();
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [loading, setLoading] = useState(true);

    // init state for lazy loading
    // from local storage or default

    const [lazyState, setlazyState] = useState(JSON.parse(localStorage.getItem('dt-state-locations-local')) || { first: 0, rows: 10, sortField: 'id', sortOrder: -1, filters: {} });

    const navigate = useNavigate();

    useHotkeys('F8', () => {
        navigate('/locations/new');
    });

    const { getCitiess } = CitiesService();

    const { getLocationssLazy } = LocationsService();

    useEffect(() => {
        loadLazyData();
        getCitiess().then((res) => {
            setCities(res.data);
            setLoading(false);
        });
    }, [lazyState]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadLazyData();
    }, [selectedCity]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        // convert filters to params
        let filters = {};
        // set fiter of city_id
        if (selectedCity) {
            filters['city_id'] = selectedCity;
        }

        // convert laziState to params
        const params = {
            page: lazyState.first / lazyState.rows + 1,
            perPage: lazyState.rows,
            sortBy: lazyState.sortField,
            sortOrder: lazyState.sortOrder > 0 ? 'asc' : 'desc',
            ...filters
        };

        getLocationssLazy(params).then((res) => {
            setModels(res.data);
        });
    };

    const renderLoaction = (rowData) => {
        return (
            <div className="col-12 md:col-6 xl:col-4 p-3" key={rowData.id}>
                <div className="surface-card shadow-2 border-round border-round-2xl p-4">
                    <div className="flex border-bottom-1 surface-border pb-4">
                        <div className="flex flex-column align-items-start">
                            <span className="text-2xl text-green-500 text-900 font-medium mb-1">{rowData.location_name}</span>
                            <span className="text-600 font-medium mb-2">
                                {rowData.city_id}, {rowData.address}
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-content-between pt-4">
                        <Link to={`/locations/${rowData.id}/view`} className="">
                            <Button label="Prikaži" icon="pi pi-eye" className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="card">
            <h1>Lokacije</h1>
            {/* city dropdown for filtering locations */}
            <div className="">
                <div className="flex align-items-center py-3 px-2 flex-wrap">
                    <div className="text-900 w-full md:w-10">
                        <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} showClear options={cities} optionValue="id" optionLabel="city_name" placeholder="Odaberite grad" className="w-full md:w-14rem p-inputtext-lg" />
                    </div>
                </div>
            </div>
            {/*  loading  spiner  */}
            {loading && (
                <div className="flex align-items-center justify-content-center p-4 ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                        </span>
                    </div>
                </div>
            )}
            {/* grid for locations */}

            <div className="grid grid-nogutter">
                {/* loop all models and renderLoaction  */}
                {models &&
                    models.map((model) => {
                        return renderLoaction(model);
                    })}
                {/* if not models found */}
                {models && models.length === 0 && (
                    <div className="col-12">
                        <div className="flex align-items-center justify-content-center p-4">
                            <div className="text-500 text-900">Nema pronađenih lokacija</div>
                        </div>
                    </div>
                )}
            </div>
            {/* <pre>{JSON.stringify(selectedCity, null, 2)}</pre> */}
        </div>
    );
};

export default Locations;
