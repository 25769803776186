// About us page  (src/pages/About.js)
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import PagesService from '../service/PagesService';

const Terms = () => {
    const [page, setPage] = useState([]);

    const { getPagesBySlug } = PagesService();

    useEffect(() => {
        getPagesBySlug('terms').then((res) => {
            setPage(res.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="card">
            <h1>{page.title}</h1>
            <div className="text-xl" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.text) }}></div>
        </div>
    );
};

export default Terms;
