import ChangesForm from './components/changes/ChangesForm';
import Locations from './components/locations/Locations';
//import LocationsMap from './components/locationsmap/LocationsMap';
import Pages from './pages/Pages';
import Post from './pages/Post';
import LocationsForm from './components/locations/LocationsForm';
import Questionnaires from './components/questionnaires/Questionnaires';
import QuestionnairesForm from './components/questionnaires/QuestionnairesForm';
import { Profile } from './pages/Profile';
import Home from './pages/Home';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';

const routes = [
    // Home
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/profile', element: <Profile /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms', element: <Terms /> },
    //{ path: '/locationsmap', element: <LocationsMap /> },
    { path: '/changes/:id/new', element: <ChangesForm /> },
    { path: '/locations', element: <Locations /> },
    { path: '/locations/:id/view', element: <LocationsForm /> },
    { path: '/questionnaires', element: <Questionnaires /> },
    { path: '/questionnaires/new', element: <QuestionnairesForm /> },
    { path: '/questionnaires/:id/edit', element: <QuestionnairesForm /> },
    { path: '/pages', element: <Pages /> },
    { path: '/post/:slug', element: <Post /> }
];

export default routes;
