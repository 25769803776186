import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BinsService from '../service/BinsService';
import { QrScaner } from '../utilities/QrScaner';

const Home = () => {
    const [showQrScaner, setShowQrScaner] = useState(false);

    const { getBinsByCode } = BinsService();

    const navigate = useNavigate();

    const onScanneHandler = (result) => {
        // console.log('result', result);
        // result format iy bin|code
        // get code from result
        const code = result.split('|')[1];

        setShowQrScaner(false);
        // navigate to bins view
        getBinsByCode(code).then((res) => {
            // console.log('res', res);
            if (res?.data) {
                navigate(`/changes/${res.data.id}/new`);
            } else {
                navigate(`/`);
            }
        });
    };

    const onQrIconClick = (event) => {
        // console.log('onQrIconClick');
        //  topbarItemClick = true;
        setShowQrScaner(true);
    };

    return (
        <div className="">
            <Dialog visible={showQrScaner} style={{ minWidth: '24rem' }} onHide={() => setShowQrScaner(false)}>
                <QrScaner onScane={onScanneHandler}></QrScaner>
            </Dialog>
            <div className="px-4 py-4  md:px-6 lg:px-8" style={{ background: 'radial-gradient(69.84% 69.84% at 50% 100%, rgba(21, 101, 192, 0.15) 0%, rgba(255, 255, 255, 0) 100%)' }}>
                <div className="grid ">
                    <div className="col-12">
                        <div className="p-5   shadow-2 flex flex-column md:flex-row align-items-center justify-content-center" style={{ borderRadius: '1rem', background: '#006E3D' }}>
                            <div className="pr-6 ">
                                <div className="text-white font-medium text-3xl">Reciklirajte ostatke jestivog ulja </div>
                            </div>
                            <div className="mt-4 mr-auto md:mt-0 md:mr-0">
                                <Link to="/about">
                                    <Button label="O nama" className="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <div
                            onClick={() => {
                                onQrIconClick();
                            }}
                            className="shadow-1 p-3 surface-card text-center border-round border-round-2xl"
                        >
                            <div className="text-900 font-medium text-2xl mb-2">Skeniraj QR</div>
                            <div className="text-700 mb-4 line-height-3">Nalazi se na kanti za odlaganje otpadnog ulja.</div>
                            <Button icon="pi pi-qrcode text-2xl pr-2" label="Pokreni skener" className="w-full font-bold p-button px-5 py-3 p-button-rounded p-button-raised white-space-nowrap" />
                        </div>
                    </div>

                    <div className="col-12 md:col-4">
                        <Link to="/locations">
                            <div className="shadow-1 p-3 surface-card text-center border-round border-round-2xl">
                                <div className="text-900 font-medium text-2xl mb-2">Lista lokacija</div>
                                <div className="text-700 mb-4 line-height-3">Lista svih lokacija sa kantama za odlaganje.</div>
                                <Button icon="pi pi-list text-2xl pr-2" label="Prikaži" className="w-full font-bold p-button-rounded px-5 py-3  p-button-raised white-space-nowrap" />
                            </div>
                        </Link>
                    </div>

                    {/* <div className="col-12 md:col-4">
                        <Link to="/locations">
                            <div className="shadow-1 p-3 surface-card text-center border-round border-round-2xl">
                                <div className="text-900 font-medium text-2xl mb-2">Pronađi na mapi</div>
                                <div className="text-700 mb-4 line-height-3">Prikaži najbližu lokaciju na google mapi.</div>
                                <Button icon="pi pi-map text-2xl pr-2" label="Prikaži" className="w-full font-bold p-button-warning p-button-rounded px-5 py-3  p-button-raised white-space-nowrap" />
                            </div>
                        </Link>
                    </div> */}

                    <div className="col-12 md:col-4">
                        <Link to="/questionnaires">
                            <div className="shadow-1 p-3 surface-card text-center border-round border-round-2xl">
                                <div className="text-900 font-medium text-2xl mb-2">Popuni anketu</div>
                                <div className="text-700 mb-4 line-height-3">Glasaj za koga će otići prikupljena sredstva.</div>
                                <Button icon="pi pi-thumbs-up text-2xl pr-2" label="Otvori anketu" className="w-full font-bold p-button-success p-button-rounded  px-5 py-3 p-button-raised white-space-nowrap" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
