import api from '../auth';
const slug = 'api/pages';

const PagesService = () => {
    const getPagess = () => {
        return api.get(slug).then((res) => res.data);
    };

    const getPagessLazy = (params) => {
        return api
            .get(slug, {
                params
            })
            .then((res) => res.data);
    };

    const getPages = (id) => {
        return api.get(slug + `/show/${id}`).then((res) => res.data);
    };

    const getPagesBySlug = (paeslug) => {
        return api.get(slug + `/show/${paeslug}`).then((res) => res.data);
    };

    const deletePages = (id) => {
        return api
            .delete(slug + `/bin/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                return err;
            });
    };

    const createPages = (data) => {
        return api.post(slug, data).then((res) => res.data);
    };

    const updatePages = (id, data) => {
        return api.put(slug + `/bin/${id}`, data).then((res) => res.data);
    };

    const getOptions = () => {
        return api.options(slug).then((res) => res.data.actions.POST);
    };

    // custom action
    const copyPages = (id) => {
        return api.post(slug + `/copy/${id}`).then((res) => res.data);
    };

    return {
        getPagess,
        getPagessLazy,
        getPages,
        deletePages,
        createPages,
        updatePages,
        getOptions,
        copyPages,
        getPagesBySlug
    };
};

export default PagesService;
